/* Navbar */

nav{
	background-color: #ffffff;
	height:72px;
	box-shadow: 1px 1px 2px rgba(0,0,0,.1);

	.navbar-default{
		border:none;

	}
	.navbar{
		background-color: #FFF;
		height:72px;
	}
	.navbar-brand{
		background:transparent url('../img/suncash-logo.png') left top no-repeat;
		width: 150px;
	    height: 53px;
	    margin-top: 8px;
	    margin-left:10px;
	
	    text-indent: 120%; 
	    white-space: nowrap; 
	    overflow: hidden;
	}
	button.navbar-toggle{
		margin-right: 0;
		margin-top: 18px;
		margin-right: 10px;
		border:none;
	}
	.navbar-collapse{
		margin-top: 5px;
		padding-top: 0;
		background-color: #FFF;
		border: none;
	}
	.navbar-nav li.active a{
		background-color: #ffae00;
		color: #fff;
	}
	.navbar-nav li a{
		display: block;
		color:$dark-font-color;

		&:hover{
			cursor:pointer !important;
			background-color: lighten(#ffae00, 20%)

		}
		
	}
}

ul.slide-nav{
	margin: 0 0 48px 0;
	padding: 0;
	list-style: none;

	li{
		margin: 0;
		padding: 12px 0;

		a{
			color: #565656;
			font-weight: 4500;
			font-size: 18px;

			&:hover{
				text-decoration: none;
				color: lighten($primary-color, 20%)
			}
		}
		

	}
	li.active-nav a{
		color: $primary-color;

	}
}
