/* Sections */
section.main-banner{
	background: #e6dcc7 url('../img/bg-main-banner-mobile.jpg') left bottom repeat-x;
	text-align: center;

	h1{
		font-size: 2em;
	}
}
section.about{
	background-color: #ffae00;
}
section.wallet-features{
	background-color: #FFF;
	text-align: center;
}
section.for-merchant{
	background-color: #FFF;
	padding: 24px 0 72px 0;
	border-radius: 4px;
}
section.download-app{
	background:#f09b00;
	color:$light-font-color;
	text-align: center !important;
	padding-top: 48px;
	padding-bottom: 48px;

	h2{
		font-size: 1.8em;
		margin-top: 24px;
		margin-bottom: 5px;
		color: $light-font-color;
	}
	p{
		margin-bottom: 48px;
	}
}
.section-terms-policy{

	padding-top: 48px;
	padding-bottom: 96px;

	h2{
		font-weight: 300;
		font-size: 36px !important;
		margin-bottom: 24px;
		color: $primary-color;
	}
	
	h3{
		padding-top: 24px;
		color: darken($base-font-color, 20%);
	}
	
	a{
		color:$primary-color;
	}

	.table-container{
		margin: 24px 0;

		thead{
			background-color: #f5f7fc;

			tr th{
				border-bottom:solid 3px #efefef;
			}
		}
	}
	ol.list-block {
		padding-left: 0;
		margin-left: 0;
		padding-left: 15px;

		ol{
			margin-left: 0;
			padding-left: 14px;
		}

	}

	ol.list-block li{
		margin-top: 48px;
		
		li{
			margin-top: 12px;
		}
	}
	
}

section.page-title{
	padding-top: 48px;
	padding-bottom: 12px;
	margin-bottom: 48px;
	

	.title-box{
		border-bottom: 1px solid #e8e8e8;
	}

	h2{
		font-size: 2.1em;
		font-weight: 300;
	}
}

section.content-wrapper{
	
	p.large-text{
		font-size: 22px;
		line-height: 35px;
		font-weight: 300;
		margin-bottom: 24px;
	}
	p{
		margin-bottom: 24px;
	}

	img{
		padding-top: 24px;
	}

	img.about-banner{
		display: none;
	}

	img.icon-img{
		width: auto;
	}
	img.img-auto{
		width:100%;
	}
}
footer.main-footer{
	background-color: #FFF;
}
footer.sub-footer{
	background-color: #FFF;
	border-top: solid 1px #e2e2e2;
}