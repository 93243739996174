/* Typography Styles */
body{
	font-family: "Roboto", sans-serif;
	font-size: 15px;
	color:$base-font-color;
	line-height:$site-line-height;
}

h1,h2,h3,h4,h5,h6{
	margin: 0;
	padding: 0;
}

h1.banner-header{
	color:$header-font-color1;
	font-weight: 300;

	b{
		font-weight: 500;
	}
}
h2{
	color: $primary-color;
	font-weight: 500;
	font-size: 24px;
	margin-bottom: 12px;
}

h2.light-header{
	color: #fff;
	font-weight: 100;
}
h3{
	color: $primary-color;
	font-weight: 500;
	font-size: 1.3em;
	margin-bottom: 24px;
	margin-top: 5px;
}
p.wallet-info{
	margin-bottom: 48px;
}
.page-title{
	h2{
		margin-left: 15px;
	}
}
.contact-details {
	h3{
		font-size: .9em;
		text-transform: uppercase;
		margin-bottom: 12px;
	}

	p{
		font-size: 1.1em;
		line-height: 1.5em;
		a{
			color: $base-font-color;

			&:hover{
				color: $primary-color;
				text-decoration: none;
			}
		}
	}
}