/* Forms */

.suncash-form{

	input{
		height: 46px;

		&:focus, &:active{
			border-color:$primary-color;
			box-shadow: 0 0 3px rgba($primary-color, .9);

		}
	}
	textarea{
		&:focus, &:active{
			border-color:$primary-color;
			box-shadow: 0 0 3px rgba($primary-color, .9);

		}
	}
}

body.suncash-login{
	background-color: $primary-color;
	
	.login-panel{
		text-align: center;
		margin-top: 30px;

		h1{
			background:transparent url('../img/logo-suncash-login.png') left top no-repeat;
			width: 200px;
	    	height: 78px;
	    	margin: 30px auto;
		
	    	text-indent: 140%; 
	    	white-space: nowrap; 
	    	overflow: hidden;
		}
		p.login-footer{
			margin: 20px auto;
			color: $light-font-color;
			font-size: 12px;

			a{
				color: $light-font-color;
				font-weight: 700;
				text-decoration: underline;
			}
		}
	}

	.login-box{
		background-color: #FFF;
		border-radius: 4px;
		padding: 35px 20px;

		a{
			color: $base-font-color;
		}
		
		.info-block{
			margin-top: 24px;

			ul{
				margin-top: -5px;
				margin-left: -40px;
				list-style: none;

				li{					
					
					a{
						font-size: 12px;
						text-transform: uppercase;
						font-weight: 500;
						color: $primary-color;
					}
				}
			}

		}

		.input-group{
			overflow: auto;
			border:solid 1px #d3d3d3;
			width: 100%;
			margin: 14px 0;
			border-radius: 3px;


			input{
				float: left;
				width: 80%;
				border:none;

				&:focus, &:active{
					box-shadow:none;
				}

			}
			i{
				float: right;
				display: block;
				font-size: 12px;
				text-align: center;
				line-height: 18px;
				margin-right: 8px;
				margin-top: 7px;
				color: $primary-color;

			}

		}
		.remember-me{
			font-size: 12px;
		}
	}
}


















