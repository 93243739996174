body{
	-webkit-font-smoothing: antialiased !important;
	margin-top: 72px;
	line-height:$site-line-height;
	background-color: #f5f7fc;
	height: 100%;
}
.center-item{
	text-align: center;
}
.mar-bot{
	margin-bottom:48px;

}
.section-pad-bot{
	padding-bottom: 84px;
}
.test{
	border: 1px solid red;
}


