/* Responsive Styles */

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) {
	section.content-wrapper{
		img{
			width: auto;
		}
	}


}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {
	a.btn-download{
		width: 200px;
		font-size: .9em;
	}
	
	section.content-wrapper{
		img.img-auto{
			width: auto;
		}
	}

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

	section.main-banner{
		text-align: left;
	}
	.navbar-nav li.active a{
		background-color: #fff !important;
		color: $primary-color !important;
	}
	.nav li a{
		padding: 18px 10px 29px 10px;
		position: relative;
		

		&:hover{
			background-color: #fff !important;
			
		}
	}
	.navbar-brand{
		margin-left: 0;
	}

	.nav li a:after{
		content: '';
  		position: absolute;
  		border-bottom: 0;
  		bottom:0;
  		left: 0;
  		width: 0%;
  		border-bottom: 3px solid $primary-color;
  		transition: 0.2s;

	}
	.nav li a:hover:after {
  		width: 100%;
	}

	section.content-wrapper{
		img{
			width: auto;
		}
		img.about-mobile-banner{
			display: none;
		}
		img.about-banner{
			display: block;
			margin:0 auto;
			width: 100%;
		}
	}
		

	section.download-app{
		background:#f09b00 url('../img/bg-download-app.png') center top no-repeat;
		background-size: cover;

		h2{
			font-size: 2.5em;
			font-weight: 400;
		}

	}
	.half-block{
		width: 50% !important;
		float: left;
		height: 320px;
	}
	.btn-link2{
		width: 50%;
	}
	.header-block{
		text-align: left;
	}	

	section.about p{
		line-height: 18px !important;
		margin-top: -5px  !important;
		font-size: 1em !important;
	}

	ul.footer-nav li{
		font-size: .9em !important;
		margin-bottom: 5px !important;
	}
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	section.main-banner{
		background:#f0e8d2 url('../img/bg-main-banner.jpg') center top no-repeat;
		.banner-text-container{
			margin-top: 120px;
		}
		h1{
			font-size: 38px;
		}
	}
	.nav li a{
		padding: 18px 18px 29px 18px;
		position: relative;
		
	}
	.half-block{
		height: 500px !important;
	}
	section.about p{
		line-height: 1.5em;
		font-size: 1.1em;
	}

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	section.content-wrapper{
		img{
			width: auto !important;
		}
	}

}
