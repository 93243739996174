/* Footer */
.main-footer{
	padding-top:48px;

	h4{
		font-size: .8em;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 24px;
	}

	ul.footer-nav{
		list-style: none;
		margin:0;
		padding:0;
		margin-bottom: 48px;

		li{
			font-size: 1.1em;
			margin-bottom: 10px;

			a{
				color: lighten($dark-font-color, 10%);

				&:hover{
					color: lighten($primary-color, 2%);
					text-decoration: none;	
					font-weight: 700;			}
			}
		}
	}

	ul.social-media-nav{
		list-style: none;
		margin:0;
		padding:0;
		margin-bottom: 48px;

		li{
			display: inline-block;
			margin-right: 3px;

			a{
				display: block;
				font-size: 1.9em;
				color: lighten($dark-font-color, 60%);
				transition: all .2s ease;

				&:hover{
					color: lighten($primary-color, 2%);
				}
			}
		}
	}
}
.sub-footer{
	overflow:auto;

	p{
		font-size: .8em;
		float: left;
		margin-top: 20px;
		margin-left: 15px;
	}
	h2{
		float: right;
		background:transparent url('../img/suncash-logo-footer.png') left top no-repeat;
		width: 135px;
	    height: 48px;
	    margin-top: 10px;
	    margin-right: 15px;
	
	    text-indent: 120%; 
	    white-space: nowrap; 
	    overflow: hidden;
	}
}