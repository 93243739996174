
/* Mixins */

@mixin border-radius($radius){
	-moz-border-radius: $radius;
	-webkit-border-radius: $radius;
	border-radius: $radius;
}

@mixin add-border ($size, $color, $position){
	@if $position == 'all'{
		border:$size solid $color;
	} @else if $position == 'top'{
		border-top: $size solid $color;
	} @else if $position == 'bottom'{
		border-bottom: $size solid $color;
	} @else if $position == 'right'{
		border-right: $size solid $color;
	} @else if $position == 'left'{
		border-left: $size solid $color;
	} @else if $position == 'top-bottom'{
		border-top: $size solid $color;
		border-bottom: $size solid $color;
	} @else if $position == 'right-left'{
		border-left: $size solid $color;
		border-right: $size solid $color;
	}
}


@mixin add-background($color){
	background: $color;
	@if $color == $light-color{
		color:#666;
	}@else{
		color: #fff;
	}
}

@mixin full-background($image-path){
	background:#383b3d url($image-path) center bottom no-repeat;
  	-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
  	background-size: cover;
}
@mixin box-shadow($left:0, $top:2px, $blur:2px, $color:#000, $inset:"") {
      -webkit-box-shadow:$left $top $blur $color #{$inset};
      -moz-box-shadow:$left $top $blur $color #{$inset};
      box-shadow:$left $top $blur $color #{$inset};
}