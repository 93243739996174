/* Buttons */
.btn-one{
	display: block;
	width: 132px;
	height:36px;
	color:$light-font-color;
	background-color: #ffc000;
	text-align: center;
	line-height: 36px;
	border-radius: 4px;
	box-shadow: 0px 1px 15px rgba(#ff9c00, 5%);
	transition: all .2s ease-out;
	font-size: .9em;
	margin-top: 30px;
	margin-bottom: 48px;

	&:hover{
		background-color: $light-color;
		color: $primary-color;
		text-decoration: none;
	}

}
a.btn-download{
	margin-top: 48px;
	display: inline-block;
	width:80%;
	height:50px;
	text-align: center;
	background-color: #FFF;
	line-height: 50px;
	color:lighten($dark-font-color, 30%);
	border-radius: 4px;
	box-shadow: 0px 1px 15px rgba(#dcdcdc, 5%);
	margin: 24px 5px 0 5px;
	transition: all .2s ease-out;
	font-size: .9em;

	i{
		margin-right: 8px;
	}

	&:hover{
		background-color: $primary-color;
		color: $light-font-color;
		text-decoration: none;
	}

}

.cta-block2{
	a.btn-download{
		box-shadow: 0px 1px 15px rgba($primary-color, 5%);
		margin: 10px 5px 0 5px;
	}
}

.btn-link{
	display: inline-block;
	background-color: $primary-color;
	color:$light-font-color;
	text-align: center;
	width:80%;
	margin-top: 40px;
	height:36px;
	line-height:36px;
	border-radius: 4px;
	font-size: .9em;
	transition: all .2s ease-out;

	&:hover{
		background-color: $primary-color;
		color: $light-font-color;
		text-decoration: none;
		box-shadow: 0px 1px 20px rgba(#b1b1b2, 80%);
	}


}

.btn-link2{
	display: inline-block;
	background-color: $primary-color;
	color:$light-font-color;
	text-align: center;
	width:100%;
	margin-top: 40px;
	height:36px;
	line-height:36px;
	border-radius: 4px;
	font-size: .9em;
	transition: all .2s ease-out;

	&:hover{
		background-color: $primary-color;
		color: $light-font-color;
		text-decoration: none;
		box-shadow: 0px 1px 20px rgba(#b1b1b2, 80%);
	}


}

.btn-brand{
	background-color: $primary-color !important;
	width:35%  !important;
	border:none;
	margin-top: -20px;
	height:40px;

	&:hover{
		background-color: lighten($primary-color, 30%)
	}
}









