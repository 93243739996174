/* Font Stylings*/

$base-font-size:15px;
$base-font-family: "Roboto", sans-serif, Arial, sans-serif;

$site-line-height: 1.5em;

/* Site Colors */

$primary-color: #f96314;
$secondary-color: #ffae00;
$dark-color: #232c3b;
$light-color: #fff;

$primary-light-color: #ffae00;
$secondary-light-color: #ffae00;


/* Text Colors */
$dark-font-color:#080808;

$base-font-color: #555;
$light-font-color: #fff;

$header-font-color1: #303030;
$header-font-color2: #fff;

$link-color: #f96314;
