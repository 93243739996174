/* Components */
.banner-text-container{
	margin-top: 96px;
	margin-bottom: 96px;
}
.img-container{
	margin-top: 24px;
}
.text-block-about{
	margin-top: 72px;
	color:$light-font-color;
}
.header-block{
	margin-top: 96px;
	padding-bottom:24px;
	margin-bottom: 48px;
	border-bottom:solid 1px #f5f7fc;
}
.img-container{

	img{
		width: 100%;
	}
}
.text-block-container{
	background-color: #f5f7fc;
	overflow:hidden;
	margin: 0 15px;
	
	.for-merchant-text{
		background:url('../img/bg-for-merchant.png') center bottom repeat-x;
	}

	.half-block{
		width: 100%;
		float: left;
		height: 430px;

		h2{
			margin:96px 0 24px 30px;
		}
		p{
			margin: 0 30px;
		}

		img{
			margin-top: -30px;
			padding-top:0;
		}
	}
}
/* FAQ Accordion Styles */
.faq-accordion{
	.panel{
		background-color: #FFF;
		border:none;

		.panel-heading{
			background-color: #FFF;
			color: $primary-color;
			padding: 14px;
			transition: all .2s ease-in;

			&:hover{
				background-color: $primary-color;
				color: $light-font-color;
				cursor: pointer;
			}

			h4{
				font-size: 14px;

				a{
					&:hover{
						text-decoration: none;
					}
				}
			}
		}
		.active{
			background-color: $primary-color;
			color: $light-font-color;
		}
		.panel-collapse{
			background-color: #ececec;
		}
		.panel-body{
			padding: 26px;
			font-size: 13px;
			
		}

	}
}


























